import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import config from '../website-config';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
  NavBarBackground,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

import { SafeText } from '../components/safeEmail';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const Contact: React.FC = () => {
  const jsonld_contact = {
    '@context': 'https://schema.org',
    '@type': 'ContactPage',
    lastReviewed: '2021-01-22T20:06:55.979Z',
    description: 'Here you can find contact information for machinelearningcompass.com.',
    inLanguage: config.lang,
    url: config.siteUrl + '/contact/',
    name: 'Contact',
  };

  return (
    <IndexLayout>
      <Helmet>
        <html lang={config.lang} />
        <title>Contact</title>

        <meta name="description" content="Contact information" />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="contact" />
        <meta property="og:title" content="Contact" />
        <meta property="og:description" content="Contact information" />
        <meta property="og:url" content={config.siteUrl + '/contact'} />
        <meta property="article:published_time" content={'2021-01-22T20:06:55.979Z'} />
        <script type="application/ld+json">{JSON.stringify(jsonld_contact)}</script>
      </Helmet>

      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain, NavBarBackground]}>
            <div css={inner}>
              <SiteNav isHome={true} />
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page" css={[PostFull, NoImage]}>
              <PostFullHeader className="post-full-header">
                <PostFullTitle className="post-full-title">Contact</PostFullTitle>
              </PostFullHeader>

              <PostFullContent className="post-full-content">
                <div className="post-content">
                  <h5>
                    Contact <br />
                  </h5>
                  <p>
                    Feel free to contact me via E-Mail or LinkedIn! :) <br />
                    <br />
                    LinkedIn:{' '}
                    <a target="_blank" href="https://linkedin.com/in/larigiba">
                      https://linkedin.com/in/larigiba
                    </a>{' '}
                    <br />
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: '1em' }}>E-Mail:</span>
                      <SafeText parts={['lari', 'machinelearningcompass', 'com']} />
                    </div>
                  </p>
                </div>
              </PostFullContent>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default Contact;
