import React, { useState, useEffect } from 'react'
import {css} from '@emotion/react'
import atImg from "./icons/at.png"
import dotImg from "./icons/dot.png"


export const SafeText = ({parts}) => {
	return (
		<span css={safeTextStyle}>
			<span>{parts[0].slice(0,2)}</span>
			<span style={{visibility:"hidden",height:"0",width:"0",opacity:"0"}}>spam@protection.xyz</span>
			<span>{parts[0].slice(2)}</span>
			<img src={atImg} alt="{a_t}"/>
			<span>{parts[1].slice(0,5)}</span>
			<span style={{visibility:"hidden",height:"0",width:"0",opacity:"0"}}>more@protection.abc</span>
			<span>{parts[1].slice(5)}</span>
			<img src={dotImg} alt="{d_o_t}"/>
			<span style={{visibility:"hidden",height:"0",width:"0",opacity:"0"}}>evenmore@protection.io</span>
			<span>{parts[2]}</span>
		</span>)
};

export const ReallySafeText = ({encodedParts, keys}) => {
    const caesarShift = (str, amount) => {
    if (amount < 0) {
        return caesarShift(str, amount + 26);
    }
    var output = "";
    for (var i = 0; i < str.length; i++) {
        // Get the character we'll be appending
        var c = str[i];

        if (c.match(/[a-z]/i)) {
        let code = str.charCodeAt(i);
        if (code >= 65 && code <= 90) {
            c = String.fromCharCode(((code - 65 + amount) % 26) + 65);
        }
        else if (code >= 97 && code <= 122) {
            c = String.fromCharCode(((code - 97 + amount) % 26) + 97);
        }
        }
        output += c;
    }
    return output;
    };

    const reverse = (s) => {
		return [...s].reverse().join("");
	}

	const [decodedPrefixOne,setDecodedPrefixOne] = useState("decoding, please wait")
	const [decodedPrefixTwo,setDecodedPrefixTwo] = useState("_")
	
	const [decodedSuffixOneOne,setDecodedSuffixOneOne] = useState("_")
	const [decodedSuffixOneTwo,setDecodedSuffixOneTwo] = useState("_")
	
	const [decodedToplevel,setDecodedToplevel] = useState("_")
		
	const decodedPrefix = caesarShift(encodedParts[0],keys[0]);
	const decodedSuffixOne = caesarShift(encodedParts[1],keys[1]);
	const decodedToplevelVal = caesarShift(encodedParts[2],keys[2]);
	
	useEffect(()=>{
		setTimeout(()=>{
			setDecodedToplevel(decodedToplevelVal)
		},800)
		setTimeout(()=>{
			setDecodedPrefixOne(decodedPrefix.slice(0,2))
			setDecodedSuffixOneTwo(reverse(decodedSuffixOne.slice(3)))
		},1200)
		setTimeout(()=>{
			setDecodedPrefixTwo(reverse(decodedPrefix.slice(2)))
			setDecodedSuffixOneOne(decodedSuffixOne.slice(0,3))
		},1500)
	},[])


	//element.setAttribute("href", y);
	//element.setAttribute("onclick", "");
	//element.firstChild.nodeValue = "Open email software";
	return (
		<span css={safeTextStyle}>
			<span>{decodedPrefixOne}</span>
			<span style={{visibility:"hidden",height:"0",width:"0",opacity:"0"}}>spam@protection.xyz</span>
			<span style={{unicodeBidi: "bidi-override",direction: "rtl"}}>{decodedPrefixTwo}</span>
			<img src={atImg} alt="{ä_t}"/>
			<span>{decodedSuffixOneOne}</span>
			<span style={{visibility:"hidden",height:"0",width:"0",opacity:"0"}}>more@protection.abc</span>
			<span style={{unicodeBidi: "bidi-override",direction: "rtl"}}>{decodedSuffixOneTwo}</span>
			<img src={dotImg} alt="<d_o-t>."/>
			<span style={{visibility:"hidden",height:"0",width:"0",opacity:"0"}}>evenmore@protection.io</span>
			<span>{decodedToplevel}</span>
		</span>)
};

const safeTextStyle=css`
position:relative;
display: flex;
justify-content: center;
align-items: center;
overflow:hidden;
span, img {
    display:inline-block;
    margin:0;
    padding:0;
}
img {
    width: 12px;
    height:12px;
}
`